import { css } from '@emotion/core';

import { buttons } from './button.styles';
import { layout } from './layout.styles';
import { color } from './mixins.styles';
import { mqFrom } from './responsive.styles';
import { typescale } from './typescale.styles';
import {
  colors,
  fontFamily,
  fontSize,
  fontWeights,
  lineHeight,
  unit,
} from './vars.styles';

const root = css`
  ${layout.wrapper};

  padding-top: ${unit}px;
  padding-bottom: ${unit * 2}px;

  ${mqFrom.Lrg} {
    padding-top: ${unit * 3}px;
    padding-bottom: ${unit * 4}px;
  }
`;

const flex = css`
  display: flex;
  justify-content: flex-start;
  padding-bottom: 80px;

  ${mqFrom.Lrg} {
    padding-top: 62px;
  }
`;

const filters = css`
  display: none;

  ${mqFrom.Lrg} {
    display: block;
    width: 290px;
    margin-right: 40px;
  }
`;

const listSection = css`
  flex: 1;
`;

const listWrapper = css`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0;
  transition: all 0.25s;

  ${mqFrom.Xlrg} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    margin-bottom: 32px;
  }
`;

const listItem = css`
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.25s ease-out;
  height: 300px;

  ${mqFrom.Sml} {
    height: 380px;
  }

  ${mqFrom.Med} {
    height: 440px;
  }

  ${mqFrom.Lrg} {
    height: 420px;
  }

  &:hover {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.5);
    cursor: pointer;

    button {
      visibility: visible;
    }
    div {
      width: 80%;
    }
  }

  & > a {
    height: 100%;
    width: 100%;
    padding: 20px 0 18px 0;

    ${mqFrom.Med} {
      padding: 28px 0 24px 0;
    }

    ${mqFrom.Lrg} {
      padding: 36px 0 30px 0;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const text = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightBold};
  ${typescale.large}

  color: ${colors.text};

  padding-bottom: ${unit * 4}px;
`;

const filterTitle = css`
  ${typescale.small}

  color: ${colors.textSecondary};
  opacity: 0.7;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: opacity 0.15s ease-out;
  margin-bottom: 20px;
`;

const accordionTitle = css`
  ${filterTitle}

  outline: none;
  cursor: pointer;
`;

const accordionItem = css`
  margin-bottom: 40px;
  text-align: left;
`;

const categoryTitle = css`
  ${typescale.large}
  font-weight: ${fontWeights.weightRegular};
  color: ${colors.dark};
  margin-bottom: 4px;

  ${mqFrom.Lrg} {
    ${typescale.xlarge}
    margin-bottom: 20px;
  }
`;

const categoryAmount = css`
  ${typescale.tiny}
  color: ${color(colors.textSecondary, 0.8)};

  ${mqFrom.Lrg} {
    ${typescale.small}
    margin-bottom: 20px;
  }
`;

const photo = css`
  width: 70%;
  height: 100%;
  transition: width 0.25s;
`;

const productText = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-style: normal;

  transition: all 0.25s;

  font-size: ${fontSize.tiny}px;
  line-height: ${lineHeight.tiny}px;

  text-align: center;
  padding: ${unit}px ${unit}px;

  ${mqFrom.Med} {
    padding: ${unit * 4}px ${unit}px;
    font-size: ${fontSize.small}px;
    line-height: ${lineHeight.small}px;
  }
  ${mqFrom.Lrg} {
    padding: ${unit * 6}px ${unit}px;
    font-size: ${fontSize.base}px;
    line-height: ${lineHeight.base}px;
  }

  color: ${colors.textSecondary};
`;

const mobileHeader = css`
  padding-bottom: 16px;
  margin-top: 47px;
  ${mqFrom.Lrg} {
    display: none;
  }
`;

const mobileFilters = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const filtersIcon = css`
  ${buttons.container}

  display: flex;
  align-items: center;

  & > p {
    text-transform: uppercase;
  }
`;

const filtersMenuWrapper = css`
  padding: 22px 20px;
`;

const filtersMenuHeader = css`
  ${typescale.large}
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
`;

const pageDescription = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  ${typescale.small}

  color: ${colors.textSecondary};
  list-style-type: circle;
`;

const pageTitle = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  ${typescale.large}

  padding-bottom: ${unit * 7}px;

  color: ${colors.dark};
`;

const descriptionWrapper = css`
  padding-bottom: 40px;
`;

const button = css`
  visibility: hidden;
`;

const copy = css`
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  grid-column: span 2;
  padding: 44px 32px;

  margin: 24px 0px;

  ${mqFrom.Xlrg} {
    margin: 0;
    grid-column: span 3;
  }
`;

const descriptionContent = css`
  display: grid;
  grid-gap: 8px 52px;

  ${mqFrom.Med} {
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    grid-gap: 8px 62px;
  }
  ${mqFrom.Lrg} {
    grid-gap: 8px 72px;
  }
  ${mqFrom.Xlrg} {
    grid-gap: 8px 122px;
  }
`;

const bulletsWrapper = css`
  list-style-position: outside;
  list-style: disc !important;
  padding-inline-start: 20px;

  ${mqFrom.Med} {
    padding: 0;
    margin: 0;
  }
`;

export const listing = {
  root,
  copy,
  flex,
  filters,
  listSection,
  listWrapper,
  listItem,
  button,
  text,
  filterTitle,
  accordionTitle,
  accordionItem,
  categoryTitle,
  categoryAmount,
  photo,
  productText,
  mobileHeader,
  mobileFilters,
  filtersIcon,
  filtersMenuWrapper,
  filtersMenuHeader,
  pageDescription,
  pageTitle,
  descriptionWrapper,
  descriptionContent,
  bulletsWrapper,
};
